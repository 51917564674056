.FnrOverlayBackdrop {
  position: fixed;
  z-index: 8000;
  top: 0;
  left: 0;

  &::before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s;
  }
  &.show::before {
    opacity: 1;
    visibility: visible;
  }
}

.FnrOverlay {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 8001;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  border: 1px solid #bec3c7;
  border-radius: 14px;
  font-size: 16px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  /* FIREFOX (and anywhere backdrop-filter isn't supported) */
  background-image: linear-gradient(
    155deg,
    rgba(255, 255, 255, 0.95) 100%,
    rgba(241, 243, 244, 0.95) 100%
  );

  /* CHROME (and anywhere backdrop-filter is supported) */
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background-image: linear-gradient(
      155deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(241, 243, 244, 0.8) 100%
    );
    backdrop-filter: blur(24px);
    -webkit-backdrop-filter: blur(24px);
  }

  & > .inside {
    padding: 24px;
  }
}
